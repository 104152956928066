
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHSearchResult',
  props: {
    type: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    productsArr: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
    },
    showResults: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },

  computed: {
    isSearchError() {
      return this.$store.getters['rph-products/searchError']
    },
  },
})
